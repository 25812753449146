@import "../../utils/breakpoints.scss";

.Grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin: 0;

  @include breakpoint(md) {
    flex-direction: row;
  }

  .col {
    width: 100%;
  }
}
