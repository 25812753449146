@import "./utils/breakpoints.scss";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin: 0 auto;
  //max-width: 1400px;
  width: 100%;

  @include breakpoint(md) {
    padding: 25px 100px;
    // justify-content: flex-start;
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    top: 25px;
    left: 0;
  }

  &.fixed {
    position: fixed;
    top: 0;
    background-color: #fff;
    padding: 1rem 2rem;
    z-index: 1000;
    @include breakpoint(md) {
      padding: 25px 100px;
    }
  }

  .headerHelper {
    display: contents;

    .logoLink {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      width: 75%;
      max-width: 200px;
      @include breakpoint(md) {
        max-width: initial;
      }

      .claim {
        //margin-top: 10px;
        border-left: #ca312d 1px solid;
        padding-left: 10px;
        font-size: 45%;
        line-height: 12px;
        @include breakpoint(sm) {
          display: block;
          font-size: 75%;
          width: 400px;
          line-height: 20px;
        }
        @include breakpoint(lg) {
          display: block;
          font-size: 80%;
          width: 400px;
          line-height: 25px;
        }
      }

      &.active {
        width: 0;
        overflow: hidden;
        height: 64px;
      }
    }
    .homeLink {
      display: none;
      &.active {
        display: block;
        width: 30px;
        order: 1;
      }
    }
  }

  .logo {
    width: 100%;
    max-width: 240px;
    height: auto;
    @include breakpoint(sm) {
      max-width: 420px;
    }
  }

  nav {
    //width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    &.active {
      justify-content: space-between;
      width: 100%;

      &::after {
        content: "";
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: -1;
        transition: transform 0.5s, opacity 0.1s;
        transition-timing-function: ease-in-out;
      }
    }

    .NaviButton {
      background: none;
      border: none;
      width: 34px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 0;
      outline: none;
      order: 2;

      span {
        border-top: 1pt solid #707070;
        margin: 5px 0;
        width: 100%;
        display: block;
        transition: transform 0.5s, opacity 0.1s;
        transition-timing-function: ease-in-out;
      }

      &.active {
        span {
          &:first-child {
            transform: rotate(45deg) translateY(7px);
          }
          &:nth-child(2) {
            transform: rotate(-45deg) translateY(-7px);
          }
          &:last-child {
            transform: translateY(70px);
            opacity: 0;
          }
        }
      }
    }

    ul {
      display: none;

      // @include breakpoint(md) {
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: flex-start;
      //   align-items: center;

      //   li {
      //     list-style-type: none;
      //     margin: 0 0.5rem;

      //     a {
      //       color: #333;
      //       text-decoration: none;
      //     }
      //   }
      // }
      &.inactive {
        height: 0%;
        opacity: 0;
        animation-name: fade-out;
        animation-duration: 0.5s;
        pointer-events: none;
      }
      &.active {
        animation-name: fade-in;
        animation-duration: 0.5s;
      }
      &.active,
      &.inactive {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        top: 120px;
        left: 0;
        z-index: 2;
        margin: 0;
        padding: 0 3rem 3rem 3rem;
        //opacity: 0;

        @include breakpoint(md) {
          padding: 50px 100px;
          // justify-content: flex-start;
        }

        li {
          list-style-type: none;
          margin: 0;
          font-size: 1rem;
          font-weight: 300;
          padding: 2rem;
          border-bottom: #aeaeae 1px solid;
          width: 100%;
          text-align: center;

          @include breakpoint(md) {
            font-size: 2rem;
          }

          &:last-child {
            border-bottom: none;
          }

          a {
            color: #333;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    top: 0px;
    height: 0%;
  }
  to {
    opacity: 1;
    top: 120px;
    height: 100%;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    top: 120px;
    height: 100%;
  }
  to {
    opacity: 0;
    top: 0px;
    height: 0%;
  }
}

footer {
  width: 100%;
  border-top: #aeaeae 1px solid;
  padding: 0rem 3rem 2rem 3rem;
  margin-top: 0rem;

  @include breakpoint(md) {
    width: 790px;
    margin: auto;
    padding: 0px 0 50px 0;
  }

  .adresse {
    .name {
      font-weight: 700;
    }
  }

  ul {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      list-style: none;
      margin-top: 2rem;

      a {
        color: #ca312d;
        text-decoration: none;
      }
    }
  }
}

.stage {
  max-width: 1200px;
  position: relative;
  color: #fff;
  font-size: 1rem;
  height: 80vh;
  padding: 0 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;

  @include breakpoint(md) {
    padding: 0 100px 4rem 100px;
    height: 800px;
  }

  &.mich {
    .Image {
      img {
        object-position: 50% 50%;
      }
    }
  }

  .Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 30% 50%;
      @include breakpoint(md) {
        object-position: 50% 50%;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 30%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  h2.Heading {
    font-size: 1.5rem;
    width: 100%;
    padding: 0;
    text-shadow: 01px 1px 3px #333333;
    text-align: left;
    font-family: "Muli", sans-serif;
    font-weight: 400;
  }

  h1.Heading {
    font-size: 3rem;
    font-family: "Muli", sans-serif;
    width: 100%;
    padding: 0;
    font-weight: 400;
    margin: 1rem 0;
    line-height: 2.5rem;
    text-shadow: 01px 1px 3px #333333;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;

    @include breakpoint(md) {
      font-size: 4rem;
      font-weight: 700;
      margin: 1rem 0;
      line-height: 3rem;
      max-width: 80%;
      word-wrap: normal;
      hyphens: none;
    }
  }

  .Text {
    padding: 0;
    font-size: 1rem;
    text-shadow: 01px 1px 3px #333333;
    @include breakpoint(md) {
      max-width: 80%;
    }
  }
}

.largeTeaser {
  width: 100%;
  position: relative;
  color: #fff;
  font-size: 1rem;
  height: 60vh;
  padding: 3rem 1rem 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include breakpoint(md) {
    padding: 1.8rem 100px 4rem 100px;
    height: 643px;
  }

  .Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      rotate: 180deg;
      object-position: 50% 120%;
      @include breakpoint(md) {
        object-position: 50% 100%;
      }
    }
  }

  h2.Heading {
    font-size: 2rem;
    font-family: "Muli", sans-serif;
    width: 100%;
    padding: 0;
    font-weight: 700;
    margin: 1rem 0;
    line-height: 2.5rem;
    text-align: center;
    text-shadow: 01px 1px 3px #333333;

    @include breakpoint(md) {
      font-size: 2.5rem;
      font-weight: 700;
      margin: 1rem 0;
      line-height: 3rem;
      max-width: 80%;
    }
  }

  .Text {
    text-shadow: 01px 1px 3px #333333;
    text-align: center;
  }
}

.magazin {
  border: 3px #333 solid;
  width: 100%;
  position: relative;
  top: 50px;
  display: flex;
  flex-direction: column;
  padding: 10% 1rem 0 1rem;
  margin-bottom: 5rem;
  margin-top: 90%;

  @include breakpoint(sm) {
    display: block;
    margin-bottom: 100px;
    width: 55%;
    margin-top: 0;
    padding: 0 2rem 0 2rem;
  }

  @include breakpoint(md) {
    margin-bottom: 200px;
  }

  @include breakpoint(xl) {
    padding: 0 100px;
  }

  &.left {
    align-self: flex-end;

    .Image {
      left: 0;

      @include breakpoint(sm) {
        left: -83%;
      }
    }
  }

  &.right {
    align-self: flex-start;

    .Image {
      right: 0;
      left: auto;

      @include breakpoint(sm) {
        right: -83%;
      }
    }
  }

  .Heading {
    padding-bottom: 1rem;
    border-bottom: 5px solid #333;
    text-align: left;
    order: 2;
  }

  .Text {
    line-height: 1.825rem;
    text-align: justify;
    margin-bottom: 1rem;
    order: 3;
    @include breakpoint(md) {
      margin-bottom: 5rem;
    }
  }

  .Image {
    width: 100%;
    padding-top: 100%;
    order: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: -70px;
    z-index: -1;
    margin-top: -90%;

    @include breakpoint(sm) {
      position: absolute;
      top: -3px;
      z-index: -1;
      width: 100%;
      max-width: 310px;
      height: 100%;
      max-height: 310px;
      padding-top: 0;
      margin-top: 0%;
    }

    @include breakpoint(lg) {
      position: absolute;
      z-index: -1;
      width: 400px;
      height: 400px;
      max-width: 410px;
      max-height: 410px;
    }

    @include breakpoint(xl) {
      position: absolute;
      z-index: -1;
      width: 500px;
      height: 500px;
      max-width: 500px;
      max-height: 500px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.contactGrid {
  background-color: #eee;
  padding: 1rem;

  @include breakpoint(md) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 100px;

    .col {
      width: 50%;

      &:first-child {
        padding: 0 50px 0 0;
      }

      &:last-child {
        padding: 0 0 0 50px;
      }
    }

    .Heading {
      text-align: left;
    }
  }

  label {
    display: none;
  }

  form {
    margin: 3rem 0 2rem 0;

    input,
    textarea {
      width: 100%;
      border: none;
      height: 3rem;
      padding: 0 0.5rem;
      border-bottom: 2px solid #ccc;
      margin-bottom: 1rem;
      background: none;
      font-family: "Muli", sans-serif;
      font-size: 1rem;
    }

    textarea {
      height: 8rem;
      font-family: "Muli", sans-serif;
    }

    button {
      width: 100%;
      border: none;
      background-color: #fff;
      padding: 1rem;
    }
  }
}

.thankyou {
  background: #eee;
  min-height: 60vh;
  text-align: center;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .Text {
    max-width: 100%;
    margin: 0 auto 3rem auto;
    @include breakpoint(sm) {
      max-width: 50%;
    }
  }
}

.e404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 350px);
}

.productTeaser {
  .Grid {
    display: flex;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }

    .col {
      width: 100%;
      padding: 1rem;

      @include breakpoint(lg) {
        width: 33%;
      }

      .Teaser {
        .headline {
          font-family: "Muli", sans-serif;
          font-weight: 700;
          margin: 1rem 0;
        }

        .text {
          font-size: 0.825rem;
          height: 64px;
          margin-bottom: 1rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.center {
  margin: auto;
  text-align: center;
}

.kontakt {
  textarea {
    height: 150px;
  }
}

.offline {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    color: #ca312d;
    text-align: center;
    line-height: 30px;
    @include breakpoint(lg) {
      line-height: 60px;
    }
  }
}

.noMargin {
  margin: 0 !important;

  p {
    margin: 0;
  }
}

.noTopMargin {
  margin-top: 0;
  padding-top: 0;
}

.centerImgDesktop {
  @include breakpoint(md) {
    text-align: center;

    p {
      text-align: center;
    }
  }
}

strong,
b {
  font-weight: 500;
}
