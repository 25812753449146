@import "../../utils/breakpoints.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.Products {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include breakpoint(sm) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .item {
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 2rem 1rem;

    @include breakpoint(sm) {
      width: 50%;
    }

    @include breakpoint(md) {
      width: 33%;
    }

    .image {
      width: 300px;
      height: 300px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      text-align: left;
      height: 2rem;
      margin-top: 1rem;
    }

    .price {
      text-align: left;
      padding: 1rem 0;
      font-weight: 700;
    }
  }
}

.rechtliches {
  width: 50%;
  font-size: 0.825rem;
  margin: auto;
}

.ProductDetail {
  width: 100%;
  max-width: 1200px;

  .slick-slider {
    .slick-prev::before,
    .slick-next::before {
      color: #333;
      opacity: 1;
    }

    .slick-prev {
      left: 25px;
      z-index: 100;
    }

    .slick-next {
      right: 25px;
      z-index: 100;
    }
  }

  .buttonCard {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    padding: 1.5% 0;
    box-sizing: border-box;
    z-index: 4000;
    display: none;

    @include breakpoint(md) {
      display: inline-block;
      position: static;
      padding: 1rem 2rem;
    }

    &.sticky {
      display: block;
      position: sticky;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      @include breakpoint(md) {
        display: none;
      }
    }

    button {
      max-width: 100%;
      width: 100%;
      padding: 1rem 0;
      font-size: 1rem;
      position: relative;
      cursor: pointer;
      //box-shadow:inset 0px 0px 0px 5px #fff;

      @include breakpoint(md) {
        display: inline-block;
        position: static;
        padding: 1rem 2rem;
      }
    }
  }

  .topWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    border-bottom: 2px solid #333;

    @include breakpoint(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .imgGallery {
      @include breakpoint(md) {
        width: 50%;
      }

      .slide {
        width: 100%;
        padding-top: 100%;
        position: relative;
        display: none;

        @include breakpoint(md) {
          &:first-child {
            display: block;
          }
        }

        .Image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .name {
      padding: 1rem 0;
      text-align: center;
      h1 {
        text-align: center;
        font-size: 2rem;
      }

      .price {
        margin-bottom: 0;
      }
    }
  }

  .bottomWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    background-color: #eee;
    padding: 1rem;
    margin-top: 3rem;
    margin-bottom: 2rem;

    @include breakpoint(sm) {
      flex-direction: row;
      justify-content: space-between;
    }

    @include breakpoint(md) {
      padding: 100px;
    }

    .description {
      padding-bottom: 2rem;

      @include breakpoint(sm) {
        width: 54%;
      }
      h2 {
        font-size: 1.2rem;
        margin: 0 0 1rem 0;
      }
    }

    .details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include breakpoint(sm) {
        width: 40%;
      }

      .info {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}
