@import "../../utils/breakpoints.scss";

.Button {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  display: inline-block;
  width: 100%;
  color: #ca312d;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid #ca312d;
  font-family: "Muli", sans-serif;
  text-align: center;
  margin-top: 1rem;

  @include breakpoint(md) {
    width: auto;
    padding: 0.5rem 3rem;
  }

  &:hover,
  &:focus {
    color: #ffffff;
    border: 1px solid #ca312d;
    background-color: #ca312d;
  }

  &.invert {
    background-color: #333333;
    color: #fff;
  }

  &.pdf {
    position: relative;
    &::before {
      content: "PDF";
      font-weight: 700;
      background-image: url("../../statics/img/download.svg");
      background-size: 16px;
      background-repeat: no-repeat;
      width: 70px;
      height: 35px;
      padding-left: 26px;
      padding-right: 6px;
      z-index: 100;
    }
  }

  &.full {
    width: 100%;
    text-align: center;

    @include breakpoint(md) {
      width: auto;
    }
  }
}
