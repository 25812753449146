/* playfair-display-sc-regular - latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  src: local("Muli"), local("Muli"),
    url("../statics/fonts/Muli-Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* playfair-display-sc-italic - latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 400;
  src: local("Muli"), local("Muli"),
    url("../statics/fonts/Muli-Italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* playfair-display-sc-700 - latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  src: local("Muli"), local("Muli"),
    url("../statics/fonts/Muli-Bold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* playfair-display-sc-700italic - latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 700;
  src: local("Muli"), local("Muli"),
    url("../statics/fonts/Muli-BoldItalic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* playfair-display-sc-900 - latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 300;
  src: local("Muli"), local("Muli"),
    url("../statics/fonts/Muli-Light.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* playfair-display-sc-900italic - latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 300;
  src: local("Muli"), local("Muli"),
    url("../statics/fonts/Muli-LightItalic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300 - latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 500;
  src: local("Muli"), local("Muli"),
    url("../statics/fonts/Muli-SemiBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-300italic - latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 500;
  src: local("Muli"), local("Muli"),
    url("../statics/fonts/Muli-SemiBoldItalic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
