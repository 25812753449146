@import "../../utils/globals.scss";
@import "../../utils/breakpoints.scss";

.Heading {
  font-family: "Muli", sans-serif;
  font-weight: 400;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0.8rem 0;
  line-height: 1em;
}

@mixin H($x, $extraSelector: null) {
  h#{$x},
  .h#{$x},
  #{$extraSelector} {
    &,
    p,
    span {
      @content;
    }
  }
}

@for $x from 1 through 6 {
  @include H($x) {
    hyphens: auto;
    width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;

    @include breakpoint(md) {
      word-wrap: normal;
      hyphens: none;
    }
  }
}

h1 {
  font-size: 1.5rem;
  font-family: "Muli", sans-serif;
  @include breakpoint(md) {
    font-size: 1.5rem;
  }
}

h2 {
  font-size: 1.5rem;
  font-family: "Muli", sans-serif;
  color: #707070;
  @include breakpoint(md) {
    font-size: 1.5rem;
  }
}

h3 {
  font-size: 1rem;
  font-family: "Muli", sans-serif;
  @include breakpoint(md) {
    font-size: 3rem;
  }
}

h4 {
  font-size: 0.8rem;
  font-family: "Muli", sans-serif;
  @include breakpoint(md) {
    font-size: 2.5rem;
  }
}

h5 {
  font-size: 0.5rem;
  font-family: "Muli", sans-serif;
  @include breakpoint(md) {
    font-size: 2rem;
  }
}
