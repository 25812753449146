@import "../../utils/breakpoints.scss";

select,
input,
textarea {
  width: 100%;
  min-height: 33px;
  text-align: center;
  padding: 0.5rem 0;
  border-radius: 5px;
  border: 1px solid #9b9b9b;
  margin: 0.5rem 0;
  font-family: "Muli", sans-serif;

  @include breakpoint(md) {
    width: 300px;
    padding: 0.5rem 3rem;
  }
}

input[type="checkbox"] {
  width: 16px;
  margin-right: 1rem;
  border-radius: 0px;
  padding: 0;
  margin-bottom: 100px;
  height: auto;
  min-height: auto;
  float: left;
  text-align: left;
  margin-left: 0;
  margin-top: 5px;
}

select {
  background: none;
  border: 1px solid #ca312d;
  color: #ca312d;
  position: relative;
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9InJlZCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjI0IiAgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBkPSJNNyAxMGw1IDEyLjYyN0wxNyAxMHoiIGZpbGw9IiNjYTMxMmQiLz48L3N2Zz4K");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 2px;
}

.label {
  display: none;
}
