@import "./utils/breakpoints.scss";
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: "Muli", sans-serif;
  font-weight: 300;
  line-height: 20px;
  color: #1d1d1b;
  font-size: 17px;
  scroll-behavior: smooth;
}

a {
  color: #333;
}
main {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  //padding: 0 3rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @include breakpoint(md) {
    margin-top: 182px;
  }

  section {
    margin: 1rem 3.375rem;
    max-width: 100%;
    width: 100%;
    padding: 2rem;

    @include breakpoint(md) {
      padding: 3rem 100px 1.5rem 100px;
      max-width: 990px;
    }
  }
}

.adresse{
  display: inline-flex;
  flex-direction: column;
  @include breakpoint(md) {
    flex-direction: row;
    span:first-child{
      &::after{
        content: "|";
        margin: 0 0.5rem;
      }
    }
  }
}
