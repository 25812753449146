.Gallery {
  width: 100%;

  img {
    width: 100vw;
    height: auto;
    object-fit: cover;
    max-width: 100%;
  }
}
