.Accordion {
  .AccordionItem {
    padding: 0.5rem 0;
    .headline {
      font-weight: 700;
      cursor: pointer;
      color: #ca312d;
      font-size: 1.2rem;
      position: relative;
      left: 20px;

      &.open::before {
        content: "-";
        margin-right: 10px;
        position: absolute;
        left: -20px;
      }
      &.close::before {
        content: "+";
        margin-right: 10px;
        position: absolute;
        left: -20px;
      }
    }
    .itemContent {
      &.open {
        display: block;
      }
      &.close {
        display: none;
      }
    }
  }
}
