@import "../../utils/breakpoints.scss";

.Video {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;

  @include breakpoint(md) {
    padding-top: 36.57%;
  }

  video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include breakpoint(md) {
      position: absolute;
      height: auto;
    }
  }
}
