.Image {
  width: 100%;
  height: auto;
  overflow: hidden;
  max-height: 80vh;
  height: auto;
  padding-top: 36.57%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
}
