@import "../../utils/breakpoints.scss";

#terminplaner {
  width: 100%;
  font-family: "Muli", sans-serif;

  section {
    margin: auto;
    padding: 1rem 3rem;

    @include breakpoint(md) {
      display: flex;
      flex-direction: column;
      padding: 1rem 100px;
    }
  }

  .next-button {
    margin-top: 30px;

    @include breakpoint(md) {
      width: 300px;
      padding: 0.5rem 3rem;
    }
  }

  .hide {
    display: none;
  }

  .terminliste {
    margin-top: 1rem;

    .termin {
      margin-top: 1rem;
    }
  }

  .currentState {
    width: 100%;
    border: 1px solid #9b9b9b;
    margin: 0.5rem 0;
    min-height: 33px;
    text-align: center;
    padding: 0.5rem 0;
    border-radius: 5px;
    color: #666;
    font-size: 0.825rem;

    @include breakpoint(md) {
      width: 300px;
    }
  }

  textarea {
    text-align: left;
    padding: 1rem;
    font-size: 0.825rem;
    min-height: 8rem;
  }

  .react-datepicker {
    width: 100%;
    border: none;

    @include breakpoint(md) {
      width: 300px;
    }

    &__header {
      background: none;
      border: none;
    }

    &__month-container {
      width: 100%;
    }

    &__current-month {
      border: 1px solid #aeaeae;
      margin-bottom: 1rem;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      font-weight: 300;
    }

    &__navigation {
      top: 20px;

      &--previous {
        border-right-color: #ca312d;
      }
      &--next {
        border-left-color: #ca312d;
      }
    }

    &__month {
      border-left: 1px solid #aeaeae;
      border-right: 1px solid #aeaeae;
      border-bottom: 1px solid #aeaeae;
      border-radius: 0px 0px 5px 5px;
      padding: 0 20px 1rem 20px;
      margin: 0;
      width: 100%;
    }

    &__day {
      color: #ca312d;
      border: 1px solid #ca312d;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin: 7px 5px;

      &:hover,
      &:focus,
      &:visited {
        border-radius: 0;
        color: #2470f1;
        border-color: #2470f1;
        background-color: transparent;
      }

      &-name {
        width: 24px;
        height: 24px;
        margin: 7px 5px;
      }

      &-names {
        border-top: 1px solid #aeaeae;
        border-left: 1px solid #aeaeae;
        border-right: 1px solid #aeaeae;
        border-radius: 5px 5px 0px 0px;
      }

      &--disabled {
        border-color: #aeaeae;
        color: #aeaeae;
        position: relative;

        &::after {
          content: "";
          width: 1px;
          background-color: #aeaeae;
          height: 31px;
          position: absolute;
          top: -4px;
          left: 10px;
          transform: rotateZ(45deg);
        }
      }
    }
  }

  .termin {
    display: inline-block;
    color: #ca312d;
    border: #ca312d 1px solid;
    padding: 0.1rem 0.5rem;
    margin: 0 0.5rem;
    cursor: pointer;

    &:hover,
    &:focus,
    &:visited,
    &.active {
      border-radius: 0;
      color: #2470f1;
      border-color: #2470f1;
      background-color: transparent;
    }

    &.hide {
      display: none;
    }

    &:first-of-type {
      margin: 0 0.5rem 0 0;
    }
  }

  .datenschutz-label {
    display: flex;
    align-items: flex-start;
  }

  .datenschutzP {
    p {
      margin: 0;
      padding: 0;
      display: inline-block;
      max-width: 300px;
      width: 100%;
    }
  }
}
